export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  Decimal: { input: any; output: any };
  GenericScalar: { input: any; output: any };
  JSONString: { input: any; output: any };
  UUID: { input: any; output: any };
};

export type AddressInput = {
  city: Scalars['String']['input'];
  countryCode: CountryCode;
  floor?: InputMaybe<Scalars['String']['input']>;
  houseNumber: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

/** An enumeration. */
export enum BillingRequestInvoiceStatusChoices {
  Approved = 'APPROVED',
  Closed = 'CLOSED',
  Created = 'CREATED',
  Disapproved = 'DISAPPROVED',
  Finalized = 'FINALIZED',
  OnHold = 'ON_HOLD',
  Open = 'OPEN',
  Submitted = 'SUBMITTED',
  ToBePaid = 'TO_BE_PAID',
}

export enum BillingRequestStatus {
  Approved = 'APPROVED',
  Disapproved = 'DISAPPROVED',
  Draft = 'DRAFT',
  Invoiced = 'INVOICED',
  Submitted = 'SUBMITTED',
}

/** An enumeration. */
export enum CollectiveInvoiceTypeChoices {
  CollectiveDebtor = 'COLLECTIVE_DEBTOR',
  CollectiveMerchant = 'COLLECTIVE_MERCHANT',
  CollectiveProduct = 'COLLECTIVE_PRODUCT',
}

/** An enumeration. */
export enum CompanyLevel {
  Branch = 'Branch',
  Company = 'Company',
  Department = 'Department',
}

/** An enumeration. */
export enum ContractTemplateStatusChoices {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
}

/** An enumeration. */
export enum CountryCode {
  Nl = 'NL',
}

export type CreateOnboardingInput = {
  contractId?: InputMaybe<Scalars['UUID']['input']>;
  data?: InputMaybe<Scalars['JSONString']['input']>;
  email: Scalars['String']['input'];
  enablePin?: InputMaybe<Scalars['Boolean']['input']>;
  enableTin?: InputMaybe<Scalars['Boolean']['input']>;
  firstName: Scalars['String']['input'];
  /** Deprecated: Onboarding app will determine the ID */
  id?: InputMaybe<Scalars['UUID']['input']>;
  templateId?: InputMaybe<Scalars['UUID']['input']>;
  userType: UserTypeEnum;
};

/** An enumeration. */
export enum DebtorContractTemplateEnum {
  DebtorMerchant = 'DEBTOR_MERCHANT',
  DebtorVendor = 'DEBTOR_VENDOR',
}

export type DebtorMerchantContractInput = {
  autoRenew?: InputMaybe<Scalars['Boolean']['input']>;
  contractTemplateId?: InputMaybe<Scalars['UUID']['input']>;
  debtorSigneeUserIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['UUID']['input'];
  merchantId?: InputMaybe<Scalars['UUID']['input']>;
  noticePeriod: Scalars['Int']['input'];
  paymentTerm: Scalars['Int']['input'];
  projectId?: InputMaybe<Scalars['UUID']['input']>;
  raw: Scalars['JSONString']['input'];
  startDate: Scalars['Date']['input'];
};

/** An enumeration. */
export enum DebtorMerchantContractStatusChoices {
  ActionRequired = 'ACTION_REQUIRED',
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Terminated = 'TERMINATED',
  Upcoming = 'UPCOMING',
}

/** An enumeration. */
export enum DebtorUserRole {
  Admin = 'Admin',
  BillingRequestApprover = 'BillingRequestApprover',
  Manager = 'Manager',
  None = 'None',
  ProjectManager = 'ProjectManager',
  User = 'User',
}

/** An enumeration. */
export enum InvoiceStatusChoices {
  Approved = 'APPROVED',
  Closed = 'CLOSED',
  Created = 'CREATED',
  Disapproved = 'DISAPPROVED',
  Finalized = 'FINALIZED',
  OnHold = 'ON_HOLD',
  Open = 'OPEN',
  PendingDebtorApproval = 'PENDING_DEBTOR_APPROVAL',
  Submitted = 'SUBMITTED',
  ToBePaid = 'TO_BE_PAID',
}

/** An enumeration. */
export enum InvoiceTypeChoices {
  FactorMerchant = 'FACTOR_MERCHANT',
  FactorProduct = 'FACTOR_PRODUCT',
  FactorVendor = 'FACTOR_VENDOR',
  MerchantDebtor = 'MERCHANT_DEBTOR',
  ProductDebtor = 'PRODUCT_DEBTOR',
  ProductMerchant = 'PRODUCT_MERCHANT',
  ProductVendor = 'PRODUCT_VENDOR',
  VendorDebtor = 'VENDOR_DEBTOR',
  VendorMerchant = 'VENDOR_MERCHANT',
}

export enum MerchantStatusChoices {
  Onboarded = 'ONBOARDED',
  RequiredApproval = 'REQUIRED_APPROVAL',
}

export enum OnboardingStatusTypeChoices {
  Onboarded = 'Onboarded',
  Pending = 'Pending',
  Revoked = 'Revoked',
}

/** An enumeration. */
export enum PaymentStatusChoices {
  Paid = 'PAID',
  Unpaid = 'UNPAID',
}

/**
 *
 *     List of Project statuses.
 *     https://www.notion.so/finqle/Contract-status-and-project-status-relationship-6b56fd551c9847e88c359609ab69c57f
 *
 */
export enum ProjectStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Overdue = 'OVERDUE',
  Upcoming = 'UPCOMING',
}

export enum SalutationTypeChoices {
  Mevr = 'Mevr',
  Mr = 'Mr',
}

export type UnitLineItemsArgumentsInputObject = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  amountWithVat?: InputMaybe<Scalars['Float']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  tariff?: InputMaybe<Scalars['String']['input']>;
  vat?: InputMaybe<Scalars['String']['input']>;
  vatDeferred?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum UserTypeChoices {
  Debtor = 'DEBTOR',
  Merchant = 'MERCHANT',
  Vendor = 'VENDOR',
}

/** An enumeration. */
export enum VatRateLevelChoices {
  Deferred = 'DEFERRED',
  Reduced_1 = 'REDUCED_1',
  Reduced_2 = 'REDUCED_2',
  Reduced_3 = 'REDUCED_3',
  Standard = 'STANDARD',
  Zero = 'ZERO',
}

/** An enumeration. */
export enum UserTypeEnum {
  Merchant = 'MERCHANT',
  Vendor = 'VENDOR',
}
