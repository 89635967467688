import { useIntl } from 'react-intl';

import { AppRoutesProps } from '@bootstrap/configs/route-access';
import { RoutePath } from '@connect/shared/constants';
import { ReactComponent as AccountTree } from '@ui/assets/icons/account_tree.svg';
import { ReactComponent as Article } from '@ui/assets/icons/article.svg';
import { ReactComponent as ContractEdit } from '@ui/assets/icons/contract-edit.svg';
import { ReactComponent as DesignServices } from '@ui/assets/icons/design-services.svg';
import { ReactComponent as Euro } from '@ui/assets/icons/euro.svg';
import { ReactComponent as Gavel } from '@ui/assets/icons/gavel.svg';
import { ReactComponent as Group } from '@ui/assets/icons/group.svg';
import { ReactComponent as GroupeAdd } from '@ui/assets/icons/group_add.svg';
import { ReactComponent as Home } from '@ui/assets/icons/home.svg';
import { ReactComponent as NoteStack } from '@ui/assets/icons/note_stack.svg';
import { ReactComponent as Payments } from '@ui/assets/icons/payments.svg';

export const useRoutes = (): AppRoutesProps[] => {
  const { formatMessage } = useIntl();

  return [
    {
      path: '/',
      title: formatMessage({ id: 'field.dashboard' }),
      icon: <Home />,
      groups: ['debtors', 'merchants', 'billingRequestApprovers'],
    },
    {
      path: '/invoicing',
      title: formatMessage({ id: 'field.invoicing' }),
      groups: ['debtors', 'merchants', 'billingRequestApprovers'],
      subRoutes: [
        {
          path: RoutePath.BillingRequests,
          title: formatMessage({ id: 'field.billingRequests' }),
          icon: <Payments />,
          groups: ['debtors', 'merchants', 'billingRequestApprovers'],
          subRoutes: [
            {
              path: `${RoutePath.BillingRequests}/new`,
              title: formatMessage({ id: 'field.hourRegistration' }),
              hidden: true,
              groups: ['merchants'],
            },
            {
              path: `${RoutePath.BillingRequests}/:id`,
              title: formatMessage({ id: 'field.hourRegistration' }),
              hidden: true,
              groups: ['debtors', 'merchants', 'billingRequestApprovers'],
            },
          ],
        },
        {
          path: RoutePath.Invoices,
          title: formatMessage({ id: 'field.invoices' }),
          icon: <NoteStack />,
          groups: ['merchants'],
          subRoutes: [
            {
              path: RoutePath.PurchaseInvoices,
              title: formatMessage({ id: 'field.invoices' }),
              hidden: true,
              groups: ['merchants'],
            },
            {
              path: `${RoutePath.Invoices}/:id`,
              title: formatMessage({ id: 'field.invoice' }),
              hidden: true,
              groups: ['merchants'],
            },
          ],
        },

        {
          path: RoutePath.CreditBillingRequests,
          title: formatMessage({ id: 'field.billingRequests' }),
          groups: ['merchants'],
          hidden: true,
          subRoutes: [
            {
              path: `${RoutePath.CreditBillingRequests}/:id`,
              title: formatMessage({ id: 'field.billingRequests' }),
              icon: <Group />,
              groups: ['merchants'],
              hidden: true,
            },
          ],
        },

        {
          path: '/credit-invoice/new',
          title: formatMessage({ id: 'field.invoices' }),
          hidden: true,
          groups: ['merchants'],
        },

        {
          path: RoutePath.TransactionStatements,
          title: formatMessage({
            id: 'transactionStatement.transactionStatements',
          }),
          groups: ['merchants'],
          icon: <Euro />,
          subRoutes: [
            {
              path: `${RoutePath.TransactionStatementsArchived}/:id`,
              hidden: true,
              title: 'transactionStatement.transactionStatements',
              groups: ['merchants'],
            },
            {
              path: `${RoutePath.TransactionStatements}/:id`,
              hidden: true,
              title: 'transactionStatement.transactionStatements',
              groups: ['merchants'],
              subRoutes: [
                {
                  path: '/transaction-statements/:id/overview',
                  hidden: true,
                  title: formatMessage({ id: 'transactionStatement.transactionStatements' }),
                  groups: ['merchants'],
                },
                {
                  path: '/transaction-statements/:id/sales-invoices',
                  hidden: true,
                  title: formatMessage({ id: 'transactionStatement.transactionStatements' }),
                  groups: ['merchants'],
                },
                {
                  path: '/transaction-statements/:id/purchase-invoices',
                  hidden: true,
                  title: formatMessage({ id: 'transactionStatement.transactionStatements' }),
                  groups: ['merchants'],
                },
                {
                  path: '/transaction-statements/:id/settlements',
                  hidden: true,
                  title: formatMessage({ id: 'transactionStatement.transactionStatements' }),
                  groups: ['merchants'],
                },
              ],
            },
          ],
        },
        {
          path: RoutePath.CollectiveInvoices,
          title: formatMessage({ id: 'field.collectiveInvoices' }),
          icon: <NoteStack />,
          groups: ['debtors'],
          subRoutes: [
            {
              path: `${RoutePath.CollectiveInvoices}/upcoming`,
              title: formatMessage({ id: 'field.collectiveInvoices' }),
              groups: ['debtors'],
              hidden: true,
            },
            {
              path: `${RoutePath.CollectiveInvoices}/:id`,
              title: formatMessage({ id: 'field.collectiveInvoices' }),
              groups: ['debtors'],
              hidden: true,
            },
          ],
        },
      ],
    },
    {
      path: '/relations',
      title: formatMessage({ id: 'field.relations' }),
      groups: ['debtors', 'merchants'],
      subRoutes: [
        {
          path: RoutePath.Accounts,
          title: formatMessage({ id: 'field.accounts' }),
          icon: <Group />,
          groups: ['debtors'],
          subRoutes: [
            {
              path: `${RoutePath.Accounts}/new`,
              title: formatMessage({ id: 'field.accounts' }),
              groups: ['debtors'],
              hidden: true,
            },
            {
              path: RoutePath.AccountsInvitations,
              title: formatMessage({ id: 'field.accounts' }),
              groups: ['debtors'],
              hidden: true,
            },
            {
              path: `${RoutePath.Accounts}/:id`,
              title: formatMessage({ id: 'field.accounts' }),
              hidden: true,
              groups: ['debtors'],
            },
          ],
        },
        {
          path: '/projects',
          title: formatMessage({ id: 'field.projects' }),
          icon: <DesignServices />,
          groups: ['debtors', 'merchants'],
        },
        {
          path: '/projects/:id',
          title: formatMessage({ id: 'field.projectDetails' }),
          hidden: true,
          groups: ['debtors', 'merchants'],
        },
        {
          path: '/contracts',
          title: formatMessage({ id: 'field.contracts' }),
          icon: <ContractEdit />,
          groups: ['debtors'],
        },
        {
          path: '/contracts/:id',
          title: formatMessage({ id: 'field.contract' }),
          hidden: true,
          groups: ['debtors'],
        },
      ],
    },
    {
      path: '/templates',
      title: formatMessage({ id: 'field.templates' }),
      groups: ['debtors'],
      subRoutes: [
        {
          path: '/contract-templates',
          title: formatMessage({ id: 'field.contract' }),
          groups: ['debtors'],
          icon: <Article />,
        },
        {
          path: '/contract-templates/new',
          title: formatMessage({ id: 'field.newTemplate' }),
          hidden: true,
          groups: ['debtors'],
        },
        {
          path: '/contract-templates/:id',
          title: formatMessage({ id: 'field.template' }),
          hidden: true,
          groups: ['debtors'],
        },
        {
          path: '/onboarding-templates',
          title: formatMessage({ id: 'field.onboarding' }),
          icon: <Article />,
          groups: ['debtors'],
        },
      ],
    },
    {
      path: '/legal',
      title: formatMessage({ id: 'field.legal' }),
      groups: ['merchants'],
      subRoutes: [
        {
          path: '/transfer-statements',
          parent: '/legal',
          title: formatMessage({ id: 'transferStatement.transferStatements' }),
          groups: ['merchants'],
          icon: <Gavel />,
        },
        {
          path: '/transfer-statements/:id',
          parent: '/legal',
          title: formatMessage({ id: 'transferStatement.transferStatements' }),
          groups: ['merchants'],
          hidden: true,
          subRoutes: [
            {
              path: '/transfer-statements/:id/overview',
              hidden: true,
              title: formatMessage({ id: 'transferStatement.transferStatements' }),
              groups: ['merchants'],
            },
            {
              path: '/transfer-statements/:id/invoices',
              hidden: true,
              title: formatMessage({ id: 'transferStatement.transferStatements' }),
              groups: ['merchants'],
            },
          ],
        },
      ],
    },
    {
      path: '/settings',
      title: formatMessage({ id: 'field.settings' }),
      groups: ['debtors', 'merchants'],
      hidden: true,
      subRoutes: [
        {
          path: '/account-profile',
          title: formatMessage({ id: 'field.profile' }),
          icon: <Group />,
          groups: ['merchants'],
          hidden: true,
        },
        {
          path: '/settings/profile',
          title: formatMessage({ id: 'field.profile' }),
          icon: <Group />,
          groups: ['debtors'],
          hidden: true,
        },
        {
          path: '/settings/users',
          title: formatMessage({ id: 'field.users' }),
          groups: ['debtors'],
          icon: <GroupeAdd />,
          hidden: true,
        },
        {
          path: '/settings/users/:id',
          title: formatMessage({ id: 'field.user' }),
          groups: ['debtors'],
          hidden: true,
        },
        {
          path: '/settings/organizations',
          title: formatMessage({ id: 'field.organizations' }),
          groups: ['debtors'],
          icon: <AccountTree />,
          hidden: true,
        },
      ],
    },
    {
      path: '/logout',
      title: formatMessage({ id: 'action.logout' }),
      hidden: true,
    },
  ];
};
